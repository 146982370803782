<template>
  <div class="container-fluid ml-10">
    <div>
      <maintenance />
    </div>
  </div>
</template>

<script>
import maintenance from "@/components/maintenance/maintenance.vue";
export default {
    components:{maintenance,}
};
</script>

<style></style>
