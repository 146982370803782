<template>
  <div class="col-md-12">
    <div class="card card-custom mb-3">
      <div class="card-header" style="border-color: var(--bordercolorform)">
        <div class="card-title">
          <h3 class="card-label cardLabelTiket">Maintenance</h3>
        </div>
        <div class="card-toolbar">
          <div class="mr-2" style="padding-top:8px">
          <date-range-picker
            v-model="dateRange"
            :autoApply="true"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            @update="updateValues"
          >
            <div slot="header" slot-scope="header" class="slot">
              <h3 style="color:#3F4254; margin:12px;">Export To Excel</h3>
              <span v-if="header.in_selection"> - in selection</span>
            </div>
            <template #input="picker" style="min-width: 350px;">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
            <template #ranges="ranges">
              <div class="ranges">
                <ul>
                  <li
                    v-for="(range, name) in ranges.ranges"
                    :key="name"
                    @click="ranges.clickRange(range)"
                  >
                    <b>{{ name }}</b>
                    <small class="text-muted"
                      >{{ range[0].toDateString() }} -
                      {{ range[1].toDateString() }}</small
                    >
                  </li>
                </ul>
              </div>
            </template>
          </date-range-picker>
          </div>
          <div class="mr-2">
            <b-button
              :disabled="!dataselected"
              @click="submitExport()"
              variant="secondary"
              >Export</b-button
            >
          </div>
          <div class="mr-2">
            <b-button
              @click="submitExportAll()"
              variant="secondary"
              >Export All</b-button
            >
          </div>
          <b-button variant="danger" class="mr-2">
            <b-link to="/historymaintenance">
              <div class="text-light">History Maintenance</div>
            </b-link>
          </b-button>
          <b-button variant="danger" class="ml-2" @click="onAdd()">
            <b-icon-plus></b-icon-plus> Create New Ticket
          </b-button>
          <b-button
            variant="danger"
            class="ml-2"
            @click="modalShowImport = !modalShowImport"
          >
            Import
          </b-button>
        </div>
      </div>
      <div style="margin: 2%">
        <DxDataGrid
          :ref="dataGridRefKey"
          :data-source="dataSource"
          key-expr="site_id"
          :show-borders="true"
          :word-wrap-enabled="false"
          :column-auto-width="false"
          :remote-operations="true"
          :column-hiding-enabled="false"
          @exporting="onExporting"
        >
          <DxSearchPanel :visible="true" />
          <DxFilterRow :visible="false" />
          <DxExport :enabled="false" />
          <DxColumn data-field="site_id" caption="Site ID" :width="100" />
          <DxColumn data-field="ticket_id" caption="Tiket ID" />
          <DxColumn data-field="site_location" caption="Site Location" />
          <DxColumn data-field="problem" caption="Problem" />
          <DxColumn data-field="asal_tiket" caption="Jenis Tiket" />
          <DxColumn data-field="saber" caption="Nama" />
          <DxColumn data-field="description" caption="Deskripsi" />
          <DxColumn
            data-field="closed_at"
            dataType="date"
            format="dd/MM/yyyy"
            caption="Closed Date"
          />
          <DxColumn
            data-field="created_at"
            dataType="date"
            format="dd/MM/yyyy"
            sort-order="desc"
            caption="Created Date"
          />
          <DxColumn :width="100" type="buttons" caption="Aksi">
            <DxButton icon="fa fa-edit" hint="Edit" :onClick="iconEdit" />
            <DxButton icon="fa fa-trash" hint="Hapus" :onClick="hapus" />
          </DxColumn>
          <DxPaging :page-size="10" />
          <DxPager
            :visible="true"
            :show-page-size-selector="showPageSizeSelector"
            :allowed-page-sizes="pageSizes"
          />
        </DxDataGrid>
      </div>
      <!-- MODAL ADD TIKET MAINTENANCE -->
      <b-modal
        ref="my-modaledit"
        v-model="modalShow"
        size="lg"
        @ok="handleOk"
        title="Create Ticket Maintenance"
        centered
      >
        <b-form @submit.stop.prevent="onSubmit">
          <div class="row">
            <div class="form-group col-6">
              <label for>Site ID <span class="text-danger">*</span></label>
              <b-form-select
                id="siteid"
                name="siteid"
                :options="optionssite"
                v-on:change="getSiteId"
                :state="validateState('siteid')"
                aria-describedby="input-role-live-feedback"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- Pilih Salah Satu --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>

            <div class="form-group col-6">
              <label for
                >Site Location <span class="text-danger">*</span></label
              >
              <b-form-input
                id="sitelocation"
                disabled="true"
                name="sitelocation"
                v-model="$v.form.sitelocation.$model"
                :state="validateState('sitelocation')"
                aria-describedby="input-1-live-feedback"
                placeholder="Site Location"
                type="text"
                class="form-control"
              ></b-form-input>
            </div>

            <div class="form-group col-6">
              <label for>Created Date <span class="text-danger">*</span></label>
              <b-form-datepicker
                id="datepicker"
                name="last_date"
                v-model="$v.form.last_date.$model"
                :state="validateState('last_date')"
                aria-describedby="input-1-live-feedback"
                class="mb-2"
              ></b-form-datepicker>
            </div>
            <div class="form-group col-6">
              <label for>Problem <span class="text-danger">*</span></label>
              <b-form-input
                id="problem"
                name="problem"
                v-model="$v.form.problem.$model"
                placeholder="Problem"
                :state="validateState('problem')"
                aria-describedby="input-1-live-feedback"
                type="text"
                class="form-control"
              ></b-form-input>
            </div>
            <div class="form-group col-6">
              <b-form-group
                id="example-input-group-role"
                label-for="select-role"
              >
                <template v-slot:label>
                  Category <span class="text-danger">*</span>
                </template>
                <b-form-select
                  id="select-categories-add"
                  v-model="$v.form.category.$model"
                  :state="validateState('category')"
                  aria-describedby="input-role-live-feedback"
                  v-on:change="getCategory"
                  :options="categories"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Pilih Salah Satu --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="form-group col-6">
              <b-form-group
                id="example-input-group-role"
                label-for="select-role"
              >
                <template v-slot:label>
                  Sub Category<span class="text-danger">*</span>
                </template>
                <b-form-select
                  id="select-subb-categories-3"
                  :disabled="form.category == ''"
                  v-model="$v.form.subcategory.$model"
                  :state="validateState('subcategory')"
                  :options="SubCategories"
                  aria-describedby="input-role-live-feedback"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Pilih Salah Satu --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="form-group col-6">
              <label for
                >Jenis Pending <span class="text-danger">*</span></label
              >
              <b-form-select
                id="jenis_pending"
                v-model="$v.form.jenis_pending.$model"
                name="jenis_pending"
                :options="optionsPending"
                :state="validateState('jenis_pending')"
                aria-describedby="input-role-live-feedback"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- Pilih Salah Satu --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
            <div class="form-group col-6">
              <label for>Description:</label>
              <b-form-input
                id="description"
                name="Description"
                placeholder="Description"
                v-model="form.description"
                aria-describedby="input-1-live-feedback"
                type="text"
                class="form-control"
              ></b-form-input>
            </div>
          </div>
        </b-form>
        <!-- <pre>{{ infoModal.content }}</pre> -->
      </b-modal>
      <!-- MODAL EDIT TIKET -->
      <b-modal
        ref="my-modaledit"
        v-model="editvisit"
        size="xl"
        title="Update New Ticket Maintenance"
        hide-footer
        centered
      >
        <DxDataGrid
          :ref="dataGridRefKey"
          :data-source="dataSourceView"
          key-expr="id"
          :show-borders="true"
          :word-wrap-enabled="false"
          :column-auto-width="false"
          :remote-operations="true"
          :column-hiding-enabled="false"
          @row-click="getListTicket"
        >
          <DxColumn data-field="ticket_id" caption="Ticket ID" />
          <DxColumn data-field="site_id" caption="Site ID" />
          <DxColumn data-field="site_location" caption="Location" />
          <DxColumn data-field="problem" caption="Problem" />
          <DxColumn
            data-field="created_at"
            caption="Tanggal"
            data-type="date"
            format="dd/MM/yyyy HH:mm:ss"
          />
          <DxColumn data-field="status" caption="Status" />
          <DxColumn data-field="description" caption="Description" />
          <DxColumn data-field="description_support" caption="Description Support" />
          <DxColumn :width="100" type="buttons" caption="Aksi">
              <DxButton icon="fa fa-edit" hint="Edit" :onClick="editHistoryTicket" />
              <!-- <DxButton icon="fa fa-trash" hint="Hapus" :onClick="hapusTicket" /> -->
          </DxColumn>
          <DxPaging :page-size="10" />
          <DxPager
            :visible="true"
            :show-page-size-selector="showPageSizeSelector"
            :allowed-page-sizes="pageSizes"
          />
        </DxDataGrid>
        <div style="margin: 2%"></div>
        <b-form @submit.stop.prevent="onSubmitEdit">
          <div class="row">
            <div class="form-group col-5">
              <label for>Nama:</label>
              <b-form-input
                id="saber"
                name="saber"
                v-model="$v.formEdit.saber.$model"
                :state="validateStateFormEdit('saber')"
                placeholder="nama"
                aria-describedby="input-1-live-feedback"
                type="text"
                class="form-control"
              ></b-form-input>
            </div>
            <div class="form-group col-5">
              <label for>Email:</label>
              <b-form-input
                id="saber"
                name="saber"
                placeholder="Email"
                v-model="$v.formEdit.email.$model"
                :state="validateStateFormEdit('email')"
                type="text"
                class="form-control"
              ></b-form-input>
            </div>
            <!-- <div class="form-group col-6">
              <label for
                >Jenis Pending <span class="text-danger"></span></label
              >
              <b-form-select
                id="jenis_pending"
                v-model="formEdit.jenis_pending"
                name="jenis_pending"
                :options="optionsPending"
                aria-describedby="input-role-live-feedback"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- Pilih Salah Satu --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div> -->
            <div class="col-2" style="display: flex;
                    align-items: center;
                    justify-content: center;">
              <b-button
                    variant="outline-white"
                    style="
                      color: var(--textlabelkuning);
                      border-color: var(--textlabelkuning);
                    "
                    size="sm"
                    @click="getListTicketGroup()"
                  >
                    <div>See History</div>
                  </b-button>
            </div>
          </div>
          <b-card-footer>
            <div class="d-flex justify-content-center">
              <!-- Masih Fungsi Cancel -->
              <b-button
                class="mr-5"
                type="submit"
                @click="tipeTicket = 'update'"
                >Update Ticket</b-button
              >
              <!-- Masih Fungsi Edit -->
              <b-button
                type="submit"
                @click="tipeTicket = 'closed'"
                class="btn btn-danger"
                >Close Ticket</b-button
              >
            </div>
          </b-card-footer>
        </b-form>
      </b-modal>

      <!--      import excel-->
      <b-modal
        ref="my-modaledit"
        v-model="modalShowImport"
        size="lg"
        title="Import Ticket Maintenance"
        hide-footer
        centered
      >
        <b-form @submit.stop.prevent="importBulk">
          <div class="row">
            <div class="form-group col-12">
              <!--              <label for>File Excel :</label>-->
              <input type="file" accept=".xlsx, .xls" @change="onFileChange" />
            </div>
            <div class="form-group col-12">
              <p class="text-danger">
                * File yang Diupload Berupa xlsx dan xls.
              </p>
            </div>
          </div>
          <b-card-footer>
            <div class="d-flex justify-content-center">
              <!-- Masih Fungsi Cancel -->
              <b-button
                class="mr-5"
                href="https://docs.google.com/spreadsheets/d/1kajQ5EsH1fTBxzydy6_nhoWK2_eQ2OOj/edit?usp=share_link&ouid=102537634134806562223&rtpof=true&sd=true"
                target="_blank"
                >Sample File</b-button
              >
              <!-- Masih Fungsi Edit -->
              <b-button
                type="submit"
                @click="importBulk()"
                class="btn btn-success"
                >Import Data</b-button
              >
            </div>
          </b-card-footer>
        </b-form>
      </b-modal>
      <b-modal
        v-model="showFormUpdateTicket"
        size="xl"
        ref="my-modalFormUpdateTicket"
        title="Update Ticket"
        hide-footer
        centered
      >
        <b-form @submit.stop.prevent="submitUpdateTicket" class="mt-4">
          <div class="row">
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Site ID"
                label-for="site id"
              >
                <b-form-input
                  id="name"
                  disabled="true"
                  name="name"
                  placeholder="Nama"
                  v-model="formUpdateTicket.site_id"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Location"
                label-for="Location"
              >
                <b-form-input
                  id="name"
                  name="name"
                  disabled="true"
                  placeholder="Nama"
                  v-model="formUpdateTicket.site_location"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Problem"
                label-for="problem"
              >
                <b-form-input
                  id="problem"
                  name="problem"
                  disabled="true"
                  placeholder="problem"
                  v-model="formUpdateTicket.problem"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Description"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  name="name"
                  placeholder="Description"
                  disabled="true"
                  v-model="formUpdateTicket.description"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Catergory"
                label-for="category"
              >
                <b-form-input
                  id="category"
                  name="category"
                  placeholder="Category"
                  disabled="true"
                  v-model="formUpdateTicket.category_name"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                id="code-input-group"
                label="Subcatergory"
                label-for="subcategory"
              >
                <b-form-input
                  id="subcategory"
                  name="subcategory"
                  placeholder="Subcatergory"
                  disabled="true"
                  v-model="formUpdateTicket.subcategory_name"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-6">
              <label for
                >Jenis Pending <span class="text-danger"></span></label
              >
              <b-form-select
                id="jenis_pending"
                v-model="formUpdateTicket.jenis_pending"
                name="jenis_pending"
                :options="optionsPending"
                aria-describedby="input-role-live-feedback"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- Pilih Salah Satu --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for=""
                  >Description Support: <span class="text-danger">*</span></label
                >
                <b-form-textarea
                  id="description_support"
                  v-model="$v.formUpdateTicket.description_support.$model"
                  :state="validateStateformUpdateTicket('description_support')"
                  aria-describedby="input-1-live-feedback"
                  placeholder="Fill out this form..."
                  required
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="mr-5"
              type="submit"
              >Update</b-button
            >
          </div>
        </b-form>
        <!-- <pre>{{ infoModal.content }}</pre> -->
      </b-modal>
      <b-modal
        v-model="showModalHistoryTicket"
        size="xl"
        ref="my-modalHistoryTicket"
        :title="ticketSelected ? 'Tikect History : '+ticketSelected.ticket_id : ''"
        hide-footer
        centered
      >
        <div class="row">
          <div class="col-12">
            <div class="card-body">
              <DxDataGrid
                :ref="dataGridRefKeyHistory"
                :data-source="dataSourceHistoryTicket"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="false"
                :column-auto-width="false"
                :remote-operations="true"
                :column-hiding-enabled="false">
                <DxColumn data-field="ticket_id" caption="Ticket ID" />
                <DxColumn data-field="category_name" caption="Category" />
                <DxColumn data-field="subcategory_name" caption="Sub Category" />
                <DxColumn data-field="description" caption="Decsription" />
                <DxColumn data-field="description_support" caption="Decsription Support" />
                <DxColumn data-field="jenis_pending" caption="Jenis Pending" />
                <DxColumn
                    data-field="updated_at"
                    caption="Tanggal"
                    data-type="date"
                    format="dd/MM/yyyy HH:mm:ss"
                />
              </DxDataGrid>
              <DxPaging
                  :page-size="5"
                  :page-index="0"
              />
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        v-model="showModalHistoryTicketGroup"
        size="xl"
        ref="my-modalHistoryTicket"
        :title="ticketSelectedGroup ? 'Tikect History : '+ticketSelectedGroup.ticket_id : ''"
        hide-footer
        centered
      >
        <div class="row">
          <div class="col-12">
            <div class="card-body">
              <DxDataGrid
                :ref="dataGridRefKeyHistoryGroup"
                :data-source="dataSourceHistoryTicketGroup"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="false"
                :column-auto-width="false"
                :remote-operations="true"
                :column-hiding-enabled="false">
                <DxColumn data-field="ticket_id" caption="Ticket ID" />
                <DxColumn data-field="saber" caption="Nama" />
                <DxColumn data-field="email" caption="Email" />
                <DxColumn
                    data-field="updated_at"
                    caption="Tanggal"
                    data-type="date"
                    format="dd/MM/yyyy HH:mm:ss"
                />
              </DxDataGrid>
              <DxPaging
                  :page-size="5"
                  :page-index="0"
              />
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxButton,
  DxExport
} from "devextreme-vue/data-grid";
import WebService from "../../core/services/api";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import * as XLSX from "xlsx/xlsx.mjs";
import { isNotEmpty } from "../../core/Apputil/Dx";
import moment from "moment";
import CustomStore from "devextreme/data/custom_store";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Axios from "axios";
const dataGridRefKey = "my-data-grid";
const dataGridRefKeyHistory = "my-data-grid-histrory";
const dataGridRefKeyHistoryGroup = "my-data-grid-histrory-group";
const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists

    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const headers = { Authorization: `Bearer ${user.access_token}` };
    return fetch(
      process.env.VUE_APP_URL_LOCAL + `/ticket-support-non-closed?${params}`,
      { headers }
    )
      .then(response => response.json())
      .then(data => {
        return {
          data: data.data,
          totalCount: data.totalCount,
          summary: data.summary,
          groupCount: data.groupCount
        };
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});
export default {
  mixins: [validationMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxButton,
    DxExport,
    DateRangePicker
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      selectedcat: null,
      tanggal_mulai: null,
      tanggal_akhir: null,
      dataselected: false,
      selectedstatus: null,
      dataGridRefKey,
      dataGridRefKeyHistory,
      dataGridRefKeyHistoryGroup,
      dataSource: store,
      modalShowImport: false,
      dateRange: { startDate, endDate },
      optionscat: [
        { value: null, text: "Please select a category" },
        { value: "a", text: "Panel Offline" },
        { value: "b", text: "Category 2" }
      ],
      optionsPending: [
        { value: "Pending Pekerjaan", text: "Pending Pekerjaan" },
        { value: "Pending Part", text: "Pending Part" },
        { value: "Reschedule", text: "Reschedule" }
      ],
      optionstatus: [
        { value: null, text: "Please select a status" },
        { value: "new", text: "new" },
        { value: "process", text: "Process" }
      ],
      itemhistory: [],
      fieldseditearly: [
        {
          key: "index",
          label: "No",
          class: "text-center"
        },
        // {
        //   key: "old_data",
        //   label: "Description",
        //   sortable: false,
        //   class: "text-center",
        // },
        {
          key: "old_data",
          label: "Problem",
          sortable: false,
          class: "text-center"
        },
        {
          key: "created_at",
          label: "Update Time",
          sortable: false,
          class: "text-center"
        },
        {
          key: "user",
          label: "PIC / Operator",
          sortable: false,
          class: "text-center"
        },
        {
          formatter: value => {
            return value ? "Yes" : "No";
          },

          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        }
      ],
      form: {
        sitelocation: "",
        siteid: "",
        last_date: "",
        problem: "",
        category: "",
        subcategory: "",
        jenis_pending: "",
        description: ""
      },
      formEdit: {
        ticket_id: "",
        siteid: "",
        sitelocation: "",
        problem: "",
        description: "",
        category: "",
        subcategory: "",
        saber: "",
        email: "",
        jenis_pending: ""
      },
      formUpdateTicket: {
        box_sn: "",
        category_id:0 ,
        category_name: "" ,
        closed_at: "",
        created_at: "",
        created_date: "",
        description: "",
        description_support: "",
        ditutup_via: "",
        email: "",
        id: 0,
        last_visit: "",
        old_ticket_id: "",
        problem: "",
        saber: "",
        site_id: "",
        site_location: "",
        status: "",
        subcategory_id: 0,
        subcategory_name: "",
        ticket_id: "",
        ticket_id_penutup: "",
        updated_at: "",
        jenis_pending: ""
      },
      detaildata: "",
      value2: "",
      tipeTicket: "",
      valuetimevisit: "",
      searchQuery: null,
      editvisit: false,
      modalShow: false,
      showModalEdit: false,
      ArrayExcel: [],
      optionssite: [],
      addsiteid: "",
      site_detail: "",
      SubCategories: [],
      addsitelocation: "",
      optionssiteedit: [],
      excelData: [],
      itemsuser: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      selectedstatdev: null,
      selectedstatusvisit: null,

      optionsstatusvisit: [
        { value: null, text: "Select Status Device" },
        { value: "a", text: "Active" },
        { value: "b", text: "Non Active" },
        { value: "c", text: "Pending Device" },
        { value: "d", text: "--" }
      ],
      optionsstatdev: [
        { value: null, text: "Select Status Visit" },
        { value: "a", text: "Priority" },
        { value: "b", text: "Priority" },
        { value: "c", text: "Priority" },
        { value: "d", text: "Priority" }
      ],
      showFormUpdateTicket: false,
      ticketSelected: null,
      dataSourceHistoryTicket: [],
      showModalHistoryTicket: false,
      ticketSelectedGroup: null,
      dataSourceHistoryTicketGroup: [],
      showModalHistoryTicketGroup: false
    };
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return moment(String(val)).format("MM/DD/YYYY");
    }
  },

  computed: {
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    },
    resultQuery() {
      if (this.searchQuery) {
        return this.items.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.fields.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    }
  },
  validations: {
    form: {
      siteid: {},
      sitelocation: {
        required
      },
      last_date: { required },
      problem: { required },
      category: { required },
      subcategory: { required },
      jenis_pending: { required }
    },
    formEdit: {
      saber: { required },
      email: { required }
    },
    formUpdateTicket: {
      id: { required },
      description_support: { required }
    }
  },
  mounted() {
    this.getDataTable();
    this.getLocalsite();
    this.getCategories();
  },
  methods: {
    validateStateformUpdateTicket(name) {
      const { $dirty, $error } = this.$v.formUpdateTicket[name];
      return $dirty ? !$error : null;
    },
    editHistoryTicket(e) {
      const ticketSelected = e.row.data;
      this.ticketSelected = null;
      this.formUpdateTicket = Object.assign({}, this.formUpdateTicket, ticketSelected);
      this.formUpdateTicket.description_support = '';
      this.showFormUpdateTicket = true;
      this.showModalHistoryTicket = false;
    },
    submitUpdateTicket(e){
      e.preventDefault();
      let postBody = Object.assign({}, this.formUpdateTicket);
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.putNewTicket(postBody.id, postBody)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              }).finally(() => {
                if(data.data.data.id){
                  this.getListTicket(data.data);
                  this.detailSupport(this.ticketSelectedGroup)
                }
              });
              this.$v.formUpdateTicket.$reset();
              this.$refs["my-modalFormUpdateTicket"].hide();
              this.showFormUpdateTicket = false;
            })
            .catch(() => {
              Swal.fire({
                title: "Kesalahan",
                text: "Terjadi Kesalahan",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    getListTicket(e) {
      this.ticketSelected = e.data;
      const store = new CustomStore({
        key: "id",
        load: function(loadOptions) {
          loadOptions['take'] = 1000;
          let params = "?";
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            // "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach(function(i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });
          params = params.slice(0, -1);

          // search by name if exists

          if (
            Array.isArray(loadOptions["filter"]) &&
            loadOptions["filter"].length > 0 &&
            loadOptions["filter"][0] === "name"
          ) {
            params += "&nama=" + loadOptions["filter"][2];
          }
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = { Authorization: `Bearer ${user.access_token}` };
          return fetch(
            process.env.VUE_APP_URL_LOCAL +
              `/ticket-new/history/${e.data.id}${params}`,
            {
              headers
            }
          )
            .then(response => response.json())
            .then(data => {
              let tempdata = [];
              if(data.data?.length > 0){
                for(let i = data.data?.length - 1; i > 0; i--){
                  tempdata.push(data.data[i].new_data);
                }
              }
              return {
                data: tempdata,
                summary: data.summary,
                totalCount: data.totalCount
              };
            })
            .catch(() => {
              throw "Data Loading Error";
            });
        },
        onLoaded: (resp) => {
          if(resp.data.length){
            resp.data.map((fd) => {
              if(fd.sub_category){
                fd['subcategory_name'] = fd.sub_category.name;
                if(fd.sub_category.category){
                  fd['category_name'] = fd.sub_category.category.name
                }
              } else {
                fd['category_name'] = '';
                fd['subcategory_name'] = '';
              }
            });
          }
        }
      });
      this.dataSourceHistoryTicket = store;
      this.showModalHistoryTicket = true;
    },
    getListTicketGroup(){
      WebService.getListTicketSupport(this.ticketSelectedGroup.id)
      .then(resp => {
        let tempdata = [];
        if(resp.data.data?.length > 0){
          for(let i = resp.data.data.length - 1; i >= 0; i--){
            tempdata.push(resp.data.data[i].new_data);
          }
          this.dataSourceHistoryTicketGroup = tempdata;
        } else {
          this.dataGridRefKeyHistoryGroup = [];
        }
        this.showModalHistoryTicketGroup = true;
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Detail`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    format_date(value) {
      if (value) {
        return moment(String(value))
          .lang("id")
          .format("DD MMMM YYYY hh:mm");
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.onSubmit();
    },
    onAdd() {
      this.modalShow = true;
      this.$v.form.$reset();
    },
    getCategories() {
      WebService.getCategoryAll().then(
        res => {
          const categories = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          categories.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.categories = selectedOptions;
          this.categories2 = selectedOptions;
        },
        () => {
        }
      );
    },
    onFileChange(event) {
      var input = event.target;
      var reader = new FileReader();
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: "binary" });
        wb.SheetNames.forEach(sheetName => {
          var rowObj = XLSX.utils.sheet_to_row_object_array(
            wb.Sheets[sheetName]
          );
          this.excelData = rowObj;
        });
      };
      reader.readAsBinaryString(input.files[0]);
    },
    importBulk() {
      let unixTime = Math.floor(Date.now() / 1000);
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${user.access_token}`
            }
          };
          this.excelData.forEach(items => {
            let data = {
              box_sn: items.box_sn, // dari site id
              category_id: items.category,
              subcategory_id: items.subcategory,
              jenis_pending: String(items.jenis_pending),
              site_id: String(items.site_id),
              site_location: items.site_location,
              status: "pending",
              problem: items.problem,
              ticket_id:
                unixTime + "." + items.site_id + "." + this.randomstr(6)
            };
            Axios.post(`${process.env.VUE_APP_URL_LOCAL}/ticket-support`, data, headers)
            .then(() => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Data berhasil ditambah",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.resetForm();
                this.hideModal();
                this.getDataTable();
            }).catch((error) => {
              Swal.fire({
                  title: "Kesalahan",
                  text: `Error ${error.response.data?.message ? error.response.data.message : ''}`,
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "Kembali",
                  confirmButtonColor: "#18328D"
                });
            });
            // WebService.addticketSupport(data)
            //   .then(() => {
            //     Swal.fire({
            //       position: "center",
            //       icon: "success",
            //       title: "Data berhasil ditambah",
            //       showConfirmButton: false,
            //       timer: 1500
            //     });
            //     this.resetForm();
            //     this.hideModal();
            //     this.getDataTable();
            //   })
            //   .catch(() => {
            //     Swal.fire({
            //       title: "Kesalahan",
            //       text: "Terjadi Kesalahan",
            //       icon: "error",
            //       showCancelButton: false,
            //       confirmButtonText: "Kembali",
            //       confirmButtonColor: "#18328D"
            //     });
            //   });
          });
        }
      });
    },
    getSiteId(data) {
      WebService.getlocalsiterefBysiteID(data).then(async res => {
        this.site_detail = res.data.data[0];
        this.form = {
          sitelocation: this.site_detail.location_name,
          siteid: res.data.data[0].site_id,
          problem: this.form.problem,
          last_date: this.form.last_date,
          category: this.form.category,
          subcategory: this.form.subcategory,
          jenis_pending: this.form.jenis_pending
        };
      });
    },
    getSiteIdEdit(data) {
      WebService.getlocalsiterefBysiteID(data).then(async res => {
        const site_detail = res.data.data[0];
        this.formEdit = {
          id: this.detaildata.id,
          ticket_id: this.detaildata.ticket_id,
          site_id: data,
          site_location: site_detail.location_name,
          saber: this.detaildata.saber,
          problem: this.detaildata.problem,
          last_visit: this.detaildata.last_visit
        };
      });
    },
    getLocalsite() {
      WebService.getlocalsiteref().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        let selectedOptionsedit;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.site_id,
            value: i.site_id
          });
        });
        this.optionssite = selectedOptions;
        selectedOptionsedit = [];
        roles.map(i => {
          return selectedOptionsedit.push({
            text: i.site_id,
            value: i.site_id
          });
        });
        this.optionssiteedit = selectedOptionsedit;
      });
    },
    getCategory(id) {
      WebService.getSubCategoryById(id).then(
        res => {
          const SubCategories = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          SubCategories.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.SubCategories = selectedOptions;
        },() => {
        }
      );
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    randomstr(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    updateValues(value) {
      this.dataselected = true;
      this.tanggal_mulai = moment(value.startDate).format("YYYY-MM-DD");
      this.tanggal_akhir = moment(value.endDate).format("YYYY-MM-DD");
    },
    submitExport() {
      let param = {
        start_date: moment(this.tanggal_mulai).subtract(7, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(this.tanggal_akhir).subtract(7, 'hours').format("YYYY-MM-DD HH:mm:ss"),
        asal: ""
      };
      WebService.exportNonClose(param)
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Maintenance");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
        });
    },
    submitExportAll() {
      let param = {
        start_date: "",
        end_date: "",
        asal: ""
      };
      WebService.exportNonClose(param)
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Maintenance");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
        });
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }
      var currentDate = new Date();
      let id = this.detaildata.id;
      let data2 = {
        category_id: this.formEdit.category,
        subcategory_id: this.formEdit.subcategory,
        jenis_pending: this.formEdit.jenis_pending, // dropdown
        site_id: this.formEdit.siteid,
        site_location: this.formEdit.sitelocation,
        status: this.tipeTicket,
        problem: this.formEdit.problem,
        ticket_id: this.formEdit.ticket_id,
        saber: this.formEdit.saber,
        email: this.formEdit.email,
        description: this.formEdit.description,
        closed_at: this.tipeTicket == "closed" ? currentDate : null
      };

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // if (this.tipeTicket == "update") {
          //   WebService.SendEmail(BodyEmail).then(res => {
          //     console.log(res.data.message);
          //   });
          // }
          return WebService.editTiketprocessTiket(id, data2)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Dirubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetFormEdit();
              this.hideModal();
              this.getDataTable();
            })
            .catch(() => {
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let unixTime = Math.floor(Date.now() / 1000);
      let data2 = {
        box_sn: this.site_detail.box_sn, // dari site id
        category_id: this.form.category,
        subcategory_id: this.form.subcategory,
        jenis_pending: this.form.jenis_pending, // dropdown
        last_visit: this.form.last_date + "T00:00:00Z",
        site_id: this.form.siteid,
        site_location: this.form.sitelocation,
        status: "pending",
        problem: this.form.problem,
        description: this.form.description,
        ticket_id: unixTime + "." + this.form.siteid + "." + this.randomstr(6)
      };

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${user.access_token}`
            }
          };
          Axios.post(`${process.env.VUE_APP_URL_LOCAL}/ticket-support`, data2, headers)
          .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.hideModal();
              this.getDataTable();
          }).catch((error) => {
            Swal.fire({
                title: "Kesalahan",
                text: `Error ${error.response.data?.message ? error.response.data.message : ''}`,
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
          });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        tiketid: "",
        siteid: "",
        sitelocation: "",
        saber: "",
        problem: ""
      };
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    hapus(value) {
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deleteticketSupport(id)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Dihapus",
                timer: 1500
              });
              this.resetForm();
              this.getDataTable();
            })
            .catch(() => {
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    hapusTicket(value) {
      const data = value.row.data;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.resetNewTicket(data.id)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Dihapus",
                timer: 1500
              }).finally(() => {
                let rowData = {
                  row : {
                    data : this.ticketSelectedGroup
                  }
                }
                this.iconEdit(rowData);
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    getDataTable() {
      this.dataGrid.refresh();
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetFormEdit() {
      this.formEdit = {
        tiketid: "",
        siteid: "",
        sitelocation: "",
        saber: "",
        problem: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
      this.$v.form.$reset();
      this.$v.formEdit.$reset();
      this.addsiteid = "";
      this.addsitelocation = "";
      this.value2 = null;
      this.form = {
        tiketid: "",
        siteid: "",
        sitelocation: "",
        saber: "",
        problem: ""
      };
    },
    hapusvisit(index) {
      this.items.splice(index, 1);
    },
    iconEdit(e) {
      this.ticketSelectedGroup = e.row.data;
      this.editvisit = true;
      const store = new CustomStore({
        key: "id",
        load: function(loadOptions) {
          let params = "?";
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            // "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach(function(i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });
          params = params.slice(0, -1);

          // search by name if exists

          if (
            Array.isArray(loadOptions["filter"]) &&
            loadOptions["filter"].length > 0 &&
            loadOptions["filter"][0] === "name"
          ) {
            params += "&nama=" + loadOptions["filter"][2];
          }
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = { Authorization: `Bearer ${user.access_token}` };
          return fetch(
            process.env.VUE_APP_URL_LOCAL +
              `/ticket-support-items/${e.row.data.id}${params}`,
            {
              headers
            }
          )
            .then(response => response.json())
            .then(data => {
              if (data.status == "error") {
                return {
                  data: [],
                  totalCount: 0,
                  summary: null,
                  groupCount: 0
                };
              } else {
                return {
                  data: data.data,
                  totalCount: data.totalCount,
                  summary: data.summary,
                  groupCount: data.groupCount
                };
              }
            })
            .catch(() => {
              // throw "Data Loading Error";
              return {
                data: [],
                totalCount: 0,
                summary: null,
                groupCount: 0
              };
            });
        },
        onLoaded: function(resp) {
          if(resp.data.length){
            resp.data.map((fd) => {
              if(fd.sub_category){
                fd['subcategory_name'] = fd.sub_category.name;
                if(fd.sub_category.category){
                  fd['category_name'] = fd.sub_category.category.name
                }
              } else {
                fd['category_name'] = '';
                fd['subcategory_name'] = '';
              }
            });
          }
        }
      });
      this.dataSourceView = store;
      this.detaildata = e.row.data;
      this.formEdit.id = this.detaildata.id;
      this.formEdit.ticket_id = this.detaildata.ticket_id;
      this.formEdit.siteid = this.detaildata.site_id;
      this.formEdit.sitelocation = this.detaildata.site_location;
      this.formEdit.problem = this.detaildata.problem;
      this.formEdit.description = this.detaildata.description;
      this.formEdit.category = this.detaildata.category_id;
      this.formEdit.subcategory = this.detaildata.subcategory_id;
      this.formEdit.saber = this.detaildata.saber;
      this.formEdit.email = this.detaildata.email;
      this.formEdit.jenis_pending = this.detaildata.jenis_pending;
      WebService.getSubCategoryById(this.detaildata.category_id).then(
        res => {
          const SubCategories = res.data.data;
          let selectedOptions;
          selectedOptions = [];
          SubCategories.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
          this.SubCategories = selectedOptions;
        },() => {
        }
      );
      WebService.gethistoryMaintenance(this.detaildata.site_id)
        .then(res => {
          this.itemhistory = res.data.data;
        })
        .catch(() => {
          this.itemhistory = [];
        });
    }
  }
};
</script>

<style scoped>
.slot {
  background-color: #ebf4f8;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn .btn-primary .btn-sm {
  margin-right: 12px;
}
.ranges {
  display: none;
}
.text-black {
  color: #000;
}
.modal-dialog.modal-custom-size {
  max-width: 1300px !important;
}
</style>
